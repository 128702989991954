import React, { useEffect, useState } from "react";

const Tag = ({ title, tagKey, filterFunction, onActive, onInactive }) => {
  const [activeTag, setActiveTag] = useState(false);

  const tagToggle = () => {
    setActiveTag(!activeTag);
  };

  useEffect(() => {
    if (activeTag) {
      onActive(tagKey, filterFunction);
    } else {
      onInactive(tagKey);
    }
  }, [activeTag, tagKey, filterFunction, onActive, onInactive]);

  return (
    <button
      className={`btn ${activeTag ? " btn-secondary " : " "} btn-rounded`}
      onClick={tagToggle}
    >
      {title}
    </button>
  );
};

export default Tag;
