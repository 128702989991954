import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import Table from "./Table";
import Modal from "./Modal";
import useToggle from "../hooks/useToggle";
import Draggable from "react-draggable"; // The default
import ConditionalWrapper from "./ConditionalWrapper";
import Tooltip from "./Tooltip";

import PermissionsCheck from "../../features/auth/Permissions/PermissionsCheck";

// Main TableRow component.
const TableRow = ({
  rowKey,
  row,
  align = "",
  className,
  tableActions,
  onLink,
  onToggle,
  onTogglePermission,
  onToggleTooltip,
  toggleDisabled = false,
  onTogglePrompt,
  onRemove,
  onRemovePermission,
  removeDisabled = false,
  expand,
  draggable,
  expandData,
  expandContent,
  nestedTableFormat,
  handleDrag,
}) => {
  // Grab length of row.
  const rowLength = Object.keys(row).length;
  const nodeRef = useRef(null); // findDOMNode() deprecated, nodeRef needed for react-draggable to have access to DOM node.
  const [isExpanded, setIsExpanded] = useState(false);
  const [dragIndex, setDragIndex] = useState("10");
  const { toggle: removeModalToggle, visible: removeModalVisible } =
    useToggle();
  const { toggle: toggleModal, visible: toggleModalVisible } = useToggle();

  const renderTableCell = (key, idx) => {
    let color = row[key]?.hasOwnProperty("color") ? row[key]?.color : undefined;
    // Nested table case:
    if (row[key]?.hasOwnProperty("table") && row[key]?.table) {
      return (
        <td className={align} key={key}>
          {
            <Table
              headers={row[key]?.headers}
              rows={row[key]?.data || []}
              hasFilter={false}
              align="center"
              hideHeader={rowKey === 0 ? false : true}
              style={{ margin: "0px", padding: "0px", boxShadow: "" }}
              hasPagination={false}
              rounded={false}
              sortOptions={{ field: key, type: "string", order: true }}
              formatFunction={nestedTableFormat}
            />
          }
        </td>
      );
    } else if (row[key]?.hasOwnProperty("custom") && row[key].custom) {
      return (
        <td className={align} key={key}>
          {row[key].layout}
        </td>
      );
    }

    // Cases where multiple data sets show in one cell.
    else if (row[key]?.hasOwnProperty("multiple") && row[key]?.multiple) {
      return (
        <td
          style={color ? { backgroundColor: color } : {}}
          className={`${align} ${draggable ? "handle" : ""}`}
          key={key}
        >
          {Object.keys(row[key]?.data).map((entryKey, idx) => {
            return (
              <p key={idx + "-multiple"} style={{ marginBottom: "0px" }}>
                {`${entryKey}: ${row[key]?.data[entryKey]}`}
              </p>
            );
          })}
        </td>
      );
    }

    // Cases where fields are clickable.
    else if (row[key]?.hasOwnProperty("onClick")) {
      if (row[key]?.hasOwnProperty("subtext")) {
        return (
          <td
            style={
              color
                ? {
                    backgroundColor: color,
                    margin: "0px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
                : {
                    margin: "0px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
            }
            className={`left ${draggable ? "handle" : ""}`}
            onClick={
              row[key]?.onClick === undefined
                ? undefined
                : (e) => row[key]?.onClick(row, e)
            }
            key={key}
          >
            {row[key]?.text}
            <p
              style={{
                fontSize: "10px",
                color: "#aaa",
                marginBottom: "0px",
              }}
            >
              {row[key]?.subtext}
            </p>
          </td>
        );
      }
      // Icon case:
      if (row[key]?.hasOwnProperty("icon")) {
        return row[key]?.hasOwnProperty("tooltip") ? (
          // Icon with tooltip
          <td
            // style={color ? { backgroundColor: color, cursor: "pointer" } : {}}
            onClick={
              row[key]?.onClick === undefined
                ? undefined
                : (e) => row[key]?.onClick(row, e)
            }
            key={key}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Tooltip
                content={row[key]?.tooltip}
                style={{ width: "24px", height: "24px" }}
                onClick={true}
                delay={800}
                key={key}
              >
                <Icon icon={row[key].icon} onClick={() => {}} bubble={true} />
              </Tooltip>
            </div>
          </td>
        ) : (
          // Icon without tooltip
          <td
            style={color ? { backgroundColor: color, cursor: "pointer" } : {}}
            onClick={
              row[key]?.onClick === undefined
                ? undefined
                : (e) => row[key]?.onClick(row, e)
            }
            className={align}
            key={key}
          >
            <Icon icon={row[key].icon} onClick={() => {}} bubble={true} />
          </td>
        );
      }
      return (
        <td
          style={color ? { backgroundColor: color } : {}}
          onClick={
            row[key]?.onClick === undefined
              ? undefined
              : (e) => row[key]?.onClick(row, e)
          }
          className={idx === 0 ? "left" : align}
          key={key}
        >
          <p
            style={
              row[key]?.onClick === undefined
                ? { margin: "0px" }
                : {
                    margin: "0px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }
            }
          >
            {row[key]?.data}
          </p>
        </td>
      );
    }

    // Cases where a main and sub text are shown.
    else if (row[key]?.hasOwnProperty("subtext")) {
      return (
        <td
          style={color ? { backgroundColor: color } : {}}
          className={`left ${draggable ? "handle" : ""}`}
          key={key}
        >
          {row[key]?.text}
          <p
            style={{
              fontSize: "10px",
              color: "#aaa",
              marginBottom: "0px",
            }}
          >
            {row[key]?.subtext}
          </p>
        </td>
      );
    }
    // Case to display an array:
    else if (Array.isArray(row[key])) {
      return (
        <td
          style={color ? { backgroundColor: color } : {}}
          className={`${align} ${draggable ? "handle" : ""}`}
          key={idx}
        >
          {row[key]?.toString()}
        </td>
      );
    }
    // Default:
    else {
      return row[key]?.hasOwnProperty("tooltip") ? (
        <Tooltip
          style={{
            display: "table-cell",
            // backgroundColor: "white",
          }}
          delay={0}
          content={row[key].tooltip}
          wrapper="td"
          direction="right"
          key={idx}
        >
          <div
            style={color !== undefined ? { backgroundColor: color } : {}}
            className={`${align} ${draggable ? "handle" : ""}`}
            key={idx}
          >
            {row[key]?.data}
          </div>
        </Tooltip>
      ) : (
        <td
          style={color !== undefined ? { backgroundColor: color } : {}}
          className={`${align} ${draggable ? "handle" : ""}`}
          key={idx}
        >
          {color !== undefined ? row[key]?.data : row[key]}
        </td>
      );
    }
  };

  let tableActionsCount = [onToggle, onLink, onRemove].filter((action) => {
    return action !== undefined;
  }).length;

  // Return table row tag.
  return [
    <ConditionalWrapper
      condition={draggable}
      key={rowKey + 1}
      wrapper={(children) => (
        <Draggable
          nodeRef={nodeRef}
          axis="y"
          handle=".handle"
          key={rowKey}
          bounds={"parent"}
          position={{ x: 0, y: 0 }} // Sets/resets position for rows.
          onStart={(e) => {
            setDragIndex("1000");
          }}
          onDrag={(e, data) => {
            if (isExpanded) {
              setIsExpanded(false); // Close expanded row on drag
            }
          }}
          onStop={(_, data) => {
            // Only drag if row is at least 30% overlaping adjacent row:
            if (Math.abs(data.y / data.node.offsetHeight) > 0.3) {
              // Calculate difference from relative position to new position:
              let diff =
                data.y > 0
                  ? Math.ceil(data.y / data.node.offsetHeight) // If dragged upwards (y negative)
                  : Math.floor(data.y / data.node.offsetHeight); // If dragged downwards(y positive).
              handleDrag(rowKey, diff); // Call table drag handler with key and difference.
            }
            setDragIndex("10");
          }}
        >
          {children}
        </Draggable>
      )}
    >
      <tr
        ref={nodeRef}
        key={rowKey}
        style={{ zIndex: dragIndex, position: "relative" }}
        className={className}
      >
        {
          // For every cell in row:
          Object.keys(row).map((key, idx) => {
            // Ignore id key.
            if (key === "id") {
              return <React.Fragment key={idx}></React.Fragment>;
            }

            if (expand && idx === 0) {
              return (
                <React.Fragment key={idx + "-expand"}>
                  <td>
                    <Icon
                      icon={!isExpanded ? "expand_more" : "expand_less"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(!isExpanded);
                      }}
                      direction="row"
                    />
                  </td>

                  {renderTableCell(key, idx)}
                </React.Fragment>
              );
            }
            // If table has actions and we're on the last cell:
            if (idx === rowLength - 1) {
              // Return final cell and action cell.
              return (
                <React.Fragment key={idx}>
                  {renderTableCell(key, idx)}
                  {tableActions && (
                    <td
                      className={`${align !== undefined ? align : ""} ${
                        tableActionsCount === 2
                          ? "grid-container halves-table"
                          : tableActionsCount === 3
                          ? "grid-container thirds-table"
                          : ""
                      }`}
                      key={idx + 1}
                      style={{ width: "auto" }}
                    >
                      {
                        // If toggle action is used:
                        onToggle ? (
                          // Conditionally wrap toggle icon with permission check:
                          <ConditionalWrapper
                            condition={onTogglePermission !== undefined}
                            key={rowKey + "-permission-wrapper"}
                            wrapper={(children) => (
                              <PermissionsCheck scopes={[onTogglePermission]}>
                                {children}
                              </PermissionsCheck>
                            )}
                          >
                            {/* Conditionally wrap toggle icon with tooltip: */}
                            <ConditionalWrapper
                              condition={onToggleTooltip !== undefined}
                              key={rowKey + "-tooltip-wrapper"}
                              wrapper={(children) => (
                                <Tooltip
                                  content={onToggleTooltip(row, rowKey)}
                                  delay={0}
                                >
                                  {children}
                                </Tooltip>
                              )}
                            >
                              <Icon
                                direction="row"
                                align={
                                  tableActionsCount === 1
                                    ? "flex-end"
                                    : "center"
                                }
                                icon="more_horiz"
                                onClick={() =>
                                  onTogglePrompt !== undefined
                                    ? toggleModal()
                                    : onToggle(row, rowKey)
                                }
                                disabled={
                                  toggleDisabled ? toggleDisabled(row) : false
                                }
                              />
                              {onTogglePrompt !== undefined ? (
                                <Modal
                                  visible={toggleModalVisible}
                                  toggle={toggleModal}
                                  title="Are you sure?"
                                  content={onTogglePrompt(row)}
                                  onConfirm={() => onToggle(row, rowKey)}
                                  confirmLabel="Yes"
                                />
                              ) : null}
                            </ConditionalWrapper>
                          </ConditionalWrapper>
                        ) : null
                      }
                      {
                        // If onLink function provided:
                        onLink ? (
                          <Link to={onLink(row["id"])}>
                            <span
                              style={{
                                fontSize: 24,
                                cursor: "pointer",
                                color: "rgba(var(--primary-black),1)",
                              }}
                              className="material-icons-outlined"
                            >
                              edit
                            </span>
                          </Link>
                        ) : null
                      }
                      {
                        // If onRemove function provided:
                        onRemove ? (
                          <ConditionalWrapper
                            condition={onRemovePermission !== undefined}
                            key={rowKey}
                            wrapper={(children) => (
                              <PermissionsCheck scopes={[onRemovePermission]}>
                                {children}
                              </PermissionsCheck>
                            )}
                          >
                            <Icon
                              direction="row"
                              align={
                                tableActionsCount === 1 ? "flex-end" : "center"
                              }
                              icon="close"
                              onClick={removeModalToggle}
                              disabled={
                                removeDisabled ? removeDisabled(row) : false
                              }
                            />
                            <Modal
                              visible={removeModalVisible}
                              toggle={removeModalToggle}
                              title="Are you sure?"
                              content={
                                <h5>{`This will remove row ${rowKey + 1}`}</h5>
                              }
                              onConfirm={() => {
                                if (isExpanded) {
                                  setIsExpanded(false);
                                }
                                onRemove(row, rowKey);
                              }}
                              confirmLabel="Yes"
                            />
                          </ConditionalWrapper>
                        ) : null
                      }
                    </td>
                  )}
                </React.Fragment>
              );
            }

            // Return cell.
            return renderTableCell(key, idx);
          })
        }
      </tr>
    </ConditionalWrapper>,
    isExpanded ? expandContent(expandData, rowKey) : null,
  ];
};

export default TableRow;
