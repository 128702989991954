// Redux Store configuration:

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userReducer from "../features/auth/authSlice";
import { adquireApi } from "./apiSlice";

export const store = configureStore({
  reducer: {
    [adquireApi.reducerPath]: adquireApi.reducer, // Api reducer.
    auth: userReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(adquireApi.middleware); // Add Api middleware.
  },
});

setupListeners(store.dispatch);
