import { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, Outlet } from "react-router-dom";
// import { processLogin } from "../features/auth/authSlice";

import Loader from "../common/components/Loader";
import Campaigns from "../features/campaigns/Campaigns";
import Placements from "../features/placements/Placements";
import Header from "../features/header/Header";
import Login from "../features/auth/Login";
import Ops from "../features/ops/Ops";
import CallbackPage from "../features/auth/CallbackPage";
import { handleRedirectCallback } from "../features/auth/authActions";
import ProtectedRoute from "../common/components/ProtectedRoute";
import useHasPermission from "../common/hooks/useHasPermission";
import { SCOPES } from "../features/auth/Permissions/permissionsMap";
const Reports = lazy(() => import("../features/reports/Reports"));

const App = () => {
  // Dispatch hook
  const dispatch = useDispatch();

  // Check for token
  const user = useSelector((state) => {
    return state.auth.user;
  });

  const canViewCampaigns = useHasPermission([SCOPES.canViewCampaigns]);
  const canViewPlacements = useHasPermission([SCOPES.canViewPlacements]);

  const canViewReporting = useHasPermission([SCOPES.canViewReporting]);
  const canViewOps = useHasPermission([SCOPES.canViewOps]);

  useEffect(() => {
    // When there isn't a stored token and a hash is recieved.
    if (!user && window.location.hash) {
      dispatch(handleRedirectCallback()); // Process login
    }
  });

  // Processing login
  if (!user && window.location.hash) {
    return <Loader message="Processing authentication" />;
  }
  // If there isn't a stored token
  if (!user?.id_token) {
    return <Login />; // Return login component.
  }

  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<h1>Home</h1>} />
        <Route
          element={<ProtectedRoute isAllowed={!!user && canViewCampaigns} />}
        >
          <Route path="campaigns/*" element={<Campaigns user={user} />} />
        </Route>
        <Route
          element={<ProtectedRoute isAllowed={!!user && canViewPlacements} />}
        >
          <Route path="placements/*" element={<Placements user={user} />} />
        </Route>

        <Route element={<ProtectedRoute isAllowed={!!user && canViewOps} />}>
          <Route path="ops/*" element={<Ops />} />
        </Route>

        <Route
          element={<ProtectedRoute isAllowed={!!user && canViewReporting} />}
        >
          <Route
            path="reports/*"
            element={
              <Suspense fallback={<Loader message="Loading Reports..." />}>
                <Reports />
              </Suspense>
            }
          />
        </Route>

        <Route path="callback_page/" element={<CallbackPage />} />
      </Route>
    </Routes>
  );
};

const AppLayout = () => {
  return (
    <div
      className="grid-container app-container"
      style={{ padding: "0px", height: window.innerHeight }}
    >
      <Header />
      <div style={{ margin: "10px 20px" }}>
        <Outlet />
      </div>
    </div>
  );
};

export default App;
