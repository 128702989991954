import useHasPermission from "../../../common/hooks/useHasPermission";

// Main Component.
// Takes child component (protected UI)
// and list of scopes.
const PermissionsCheck = ({ children, scopes = [] }) => {
  const permissionGranted = useHasPermission(scopes);

  if (!permissionGranted) return <></>;
  return <>{children}</>;
};

export default PermissionsCheck;
