// HeaderItems.js
import React from "react";

import NavigationDropdown from "./NavigationDropdown";

const HeaderItem = ({
  itemType,
  icon,
  text,
  src,
  paths = [],
  prefix = "",
  pathSpecificPrefixes,
  onClick,
  style,
}) => {
  const itemTypeRender = () => {
    switch (itemType) {
      // Return logo:
      case "logo":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img className="header-logo" alt="" src={src} />
          </div>
        );

      // Return a navigation dropdown.
      case "nav":
        return (
          <NavigationDropdown
            text={text}
            icon={icon}
            paths={paths}
            prefix={prefix}
            pathSpecificPrefixes={pathSpecificPrefixes}
          />
        );

      // Default to return link
      default:
        return (
          <div style={style} onClick={onClick}>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <span className="material-icons-outlined">{icon}</span>
              {text}
            </span>
          </div>
        );
    }
  };

  return <li className="header-item">{itemTypeRender()}</li>;
};

export default HeaderItem;
