import React from "react";

const ErrorHandler = ({ err }) => {
  const status = err.hasOwnProperty("originalStatus")
    ? err.originalStatus
    : err.status;
  const message = err.hasOwnProperty("originalStatus")
    ? err.status
    : err.hasOwnProperty("data")
    ? err.data.message
    : null;
  const error = err.hasOwnProperty("error") ? <span>{err.error}</span> : null;
  return (
    <div className="error-container">
      <h1 style={{ color: "rgba(var(--secondary-red),1)" }}>{status}</h1>
      <p style={{ margin: "0px" }}>{message}</p>
      {error}
    </div>
  );
};

export default ErrorHandler;
