import React from "react";

const Icon = ({
  icon,
  background = false,
  color,
  rounded = false,
  fontSize = "24px",
  onClick,
  align = "center",
  direction = "column",
  bubble = false, // Allow bubbling on click (to trigger tooltip).
  margin = "0px",
  padding = "0px",
  inline = false,
  disabled = false,
}) => {
  if (!icon) {
    return <></>;
  }

  return background ? (
    <div
      style={{
        backgroundColor: color,
        width: fontSize,
        height: fontSize,
      }}
      className={rounded ? "icon-container rounded" : "icon-container"}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: align,
          fontSize: fontSize,
          cursor: disabled ? "not-allowed" : onClick ? "pointer" : "",
          color: "white",
        }}
        className="material-icons-outlined"
        onClick={disabled ? () => {} : onClick}
      >
        {icon}
      </span>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        justifyContent: align,
      }}
      onClick={(e) => {
        e.preventDefault();
        if (!bubble) {
          e.stopPropagation();
        }
      }}
    >
      <span
        style={{
          display: inline ? "inline-block" : "flex",
          flexDirection: inline ? "row" : "column",
          cursor: disabled ? "not-allowed" : onClick ? "pointer" : "",
          fontSize: fontSize,
          color: color,
          margin: margin,
          padding: padding,
        }}
        className="material-icons-outlined"
        onClick={disabled ? () => {} : onClick}
      >
        {icon}
      </span>
    </div>
  );
};

export default Icon;
