import React, { useCallback, useState } from "react";
import useToggle from "../../common/hooks/useToggle";
import Modal from "../../common/components/Modal";
import Filter from "../../common/components/Filter";
import useFilter from "../../common/hooks/useFilter";
import Tag from "../../common/components/Tag";
import { Link } from "react-router-dom";

// Advanced Filter Component.
const AdvancedFilter = ({ data }) => {
  const { toggle, visible } = useToggle(); // Call the modal hook for visibility management.
  const [activeTags, setActiveTags] = useState({}); // Array of tags(functions) to be applied on campaigns
  const { filter, updateFilter, filteredData } = useFilter(data, ["name"]); // Call filter hook for basic filter functionality.

  // Function that returns array of campaigns that meet all active tag requirements.
  const getTaggedData = () => {
    // Filter through campaigns.
    const taggedData = filteredData.filter((campaign) => {
      let valid = true; // Bool flag for validation.

      // Loop through active tags:
      filterKeys.forEach((key) => {
        // If campaign invalid set flag to false
        if (!activeTags[key](campaign)) {
          valid = false;
        }
      });
      return valid;
    });
    return taggedData; // Return array.
  };

  // Callback for setting a tag as active.
  const onActiveTag = useCallback((key, fn) => {
    // Local state change (immutable).
    setActiveTags((prevState) => ({
      ...prevState,
      [key]: fn,
    }));
  }, []);

  // Callback for removing an active tag.
  const onInactiveTag = useCallback((key) => {
    // Local state change (immutable).
    setActiveTags((prevState) => {
      const { [key]: value, ...remainingTags } = prevState;
      return remainingTags;
    });
  }, []);

  // Array of active tag keys (tagKeys from the Tag components).
  let filterKeys = Object.keys(activeTags);

  // Determine array of campaigns to display.
  let campaigns = filterKeys.length ? getTaggedData() : filteredData;

  // Modal render content.
  const modalContent = (
    <div
      className="grid-container halves"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: "0px",
      }}
    >
      <div className="advanced-filter-tools">
        <div className="filter-container">
          <Filter keyword={filter} setKeyword={updateFilter} />
        </div>

        <div className="grid-container halves">
          <Tag
            title="Active"
            tagKey="active"
            // Custom function (for inside an array.filter), depends on desired functionality.
            filterFunction={useCallback((val) => {
              return val.isActive;
            }, [])}
            onActive={onActiveTag}
            onInactive={onInactiveTag}
          />

          <Tag
            title="Email DOIF"
            tagKey="emailDOIF"
            // Custom function (for inside an array.filter), depends on desired functionality.
            filterFunction={useCallback((val) => {
              return val.validators.find((validator) => {
                if (validator.serviceCode === 5) {
                  return true;
                } else {
                  return false;
                }
              });
            }, [])}
            onActive={onActiveTag}
            onInactive={onInactiveTag}
          />
          <Tag
            title="Household DOIF"
            tagKey="householdDOIF"
            // Custom function (for inside an array.filter), depends on desired functionality.
            filterFunction={useCallback((val) => {
              return val.validators.find((validator) => {
                if (validator.serviceCode === 10) {
                  return true;
                } else {
                  return false;
                }
              });
            }, [])}
            onActive={onActiveTag}
            onInactive={onInactiveTag}
          />
          <Tag
            title="PIT"
            tagKey="pit"
            // Custom function (for inside an array.filter), depends on desired functionality.
            filterFunction={useCallback((val) => {
              return val.validators.find((validator) => {
                if (validator.serviceCode === 19) {
                  return true;
                } else {
                  return false;
                }
              });
            }, [])}
            onActive={onActiveTag}
            onInactive={onInactiveTag}
          />
        </div>
      </div>
      <div className="advanced-filter-display">
        <ul style={{ textAlign: "left" }}>
          {campaigns.length ? (
            campaigns.map((campaign, idx) => {
              return (
                <Link to={`${campaign.UUID}/details`} key={idx}>
                  <div style={{ color: "rgba(var(--primary-blue),1)" }}>
                    {campaign.name}
                  </div>
                </Link>
              );
            })
          ) : (
            <li>No matches found</li>
          )}
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <button onClick={toggle} className="btn btn-secondary btn-rounded">
        Advanced Filters
      </button>
      <Modal
        title="Advanced Filter"
        content={modalContent}
        visible={visible}
        toggle={toggle}
      />
    </>
  );
};

export default AdvancedFilter;
