import { useSelector } from "react-redux";

const useGetRoles = () => {
  const roles = useSelector((state) => {
    if (state.auth.user === null) {
      return [];
    }

    const memberOf = state.auth.user.profile.memberOf.filter((m) =>
      m.includes("ADQMGR")
    );

    return memberOf.map((m) => m.split(" ")[1].split(",")[0]);
  });
  return { roles };
};

export default useGetRoles;
