import React from "react";
import { Link } from "react-router-dom";
import { upperCaseFirstChar } from "../../common/utils/utils";
import useToggle from "../../common/hooks/useToggle";
import PermissionsCheck from "../auth/Permissions/PermissionsCheck";

const NavigationDropdown = ({
  text,
  icon,
  paths,
  prefix = "",
  pathSpecificPrefixes,
}) => {
  const { visible, toggle } = useToggle();
  const renderPaths = () => {
    // For every path in dropdown:
    return paths.map((path, idx) => {
      let pathText = upperCaseFirstChar(path.text);
      let pathLink = `${prefix}${
        pathSpecificPrefixes?.length ? pathSpecificPrefixes[idx] : ""
      }${path.text.replace(/ /g, "_").toLowerCase()}`;
      return path.hasOwnProperty("permission") ? (
        <PermissionsCheck key={idx} scopes={[...path.permission]}>
          <li key={idx} onClick={(e) => e.stopPropagation()}>
            <Link style={{ whiteSpace: "nowrap" }} to={pathLink}>
              {pathText}
            </Link>
          </li>
        </PermissionsCheck>
      ) : (
        <li key={idx} onClick={(e) => e.stopPropagation()}>
          <Link style={{ whiteSpace: "nowrap" }} to={pathLink}>
            {pathText}
          </Link>
        </li>
      );
    });
  };

  return (
    <div style={{ margin: "0px" }} onClick={toggle}>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <span className="material-icons-outlined">{icon}</span>
        {text}
      </span>
      {visible ? (
        <div>
          <ul style={{ margin: "0px 0px 0px 10px" }}>{renderPaths()}</ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NavigationDropdown;
