import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleRedirectCallback } from "./authActions";
import Loader from "../../common/components/Loader";

const CallbackPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(handleRedirectCallback());
  });

  return <Loader message="Processing authentication" />;
};

export default CallbackPage;
