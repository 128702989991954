import { useCallback, useState } from "react";

const useToggle = (init = false) => {
  const [visible, setVisible] = useState(init);
  const toggle = useCallback((e) => {
    if (e !== undefined) {
      e.preventDefault();
    }

    setVisible((v) => !v);
  }, []);
  return { toggle, visible };
};

export default useToggle;
