import React from "react";
import "../styles/pagination.css";
import Icon from "./Icon";

const Pagination = ({ totalPages, currentPage, handlePageChange }) => {
  return (
    <ul className="page-container">
      <li>
        <button
          className={
            currentPage === 0 || totalPages.length === 0
              ? "btn no-hover btn-rounded"
              : "btn btn-secondary btn-rounded"
          }
          style={{ padding: "0px" }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0 || totalPages.length === 0}
        >
          <Icon icon="navigate_before" bubble={true} />
        </button>
      </li>
      {/* Render page li's */}
      {totalPages.map((pageNumber, idx) => {
        return (
          <li
            className={currentPage === pageNumber - 1 ? "active" : null}
            key={idx}
            id={idx}
            onClick={
              pageNumber > 0
                ? () => handlePageChange(pageNumber - 1)
                : undefined
            }
            style={pageNumber < 0 ? { cursor: "default" } : {}}
          >
            {pageNumber < 0 ? "..." : pageNumber - 1}
          </li>
        );
      })}

      <li>
        <button
          className={
            currentPage === totalPages[totalPages.length - 1] - 1 ||
            totalPages.length === 0
              ? "btn no-hover btn-rounded"
              : "btn btn-secondary btn-rounded"
          }
          style={{ padding: "0px" }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={
            currentPage === totalPages[totalPages.length - 1] - 1 ||
            totalPages.length === 0
          }
        >
          <Icon icon="navigate_next" bubble={true} />
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
