import React from "react";

// Import animation.
import "../styles/loader.css";

// Loading component.
const Loader = ({ message = "", style }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <div className="loader" style={{ ...style }} />
      <span>{message.length ? message : ""}</span>
    </div>
  );
};

export default Loader;
