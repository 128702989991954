import useGetRoles from "./useGetRole";
import { PERMISSIONS } from "../../features/auth/Permissions/permissionsMap";

const useHasPermission = (scopes) => {
  const { roles } = useGetRoles();
  const permissions = roles
    .map((role) => PERMISSIONS[role.toUpperCase()]) // Get permissions for each user role
    .flat(); // flatten nested values.

  const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};

    scopes.forEach((scope) => {
      scopesMap[scope] = true;
    });

    // At least one of the required permissions is present in the user's role.
    return permissions.some((permission) => scopesMap[permission]);
  };

  const permissionGranted = hasPermission({
    permissions: permissions.filter((p, idx) => {
      // remove duplicate permissions.
      return permissions.indexOf(p) === idx;
    }),
    scopes,
  });

  return permissionGranted;
};

export default useHasPermission;
